import { useAuth0 } from "@auth0/auth0-react";
import { Button, Modal } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import {
    StyledFlexContainer,
    StyledModalContainer,
} from "src/components/PaymentRequestExport/PaymentRequestExport.styled";
import {
    PAYMENT_REQUEST_EXPORT_TAB,
    PaymentRequestExportInitialState,
} from "src/constants";
import { ProductNameToIdMap } from "src/constants/ProductIds";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { ScheduleService, SubAccountOptionsService } from "src/services";
import { scheduleOnlyFieldsQuery } from "src/services/GQLQueries";
import GQLService from "src/services/GQLService";
import { getModuleId, getScheduleId } from "src/services/Utility";
import { ExportSchedulePayload, FixMeLater } from "src/types";
import { downloadFile } from "src/utils";
import ContainerWithResizableSideBar from "../ContainerWithResizableSideBar/ContainerWithResizableSideBar";
import Loader from "../Loader/Loader";
import "./PaymentRequestExport.scss";
import FixedWidth from "./components/FixedWidth/FixedWidth";
import General from "./components/General/General";
import Menu from "./components/Menu/Menu";
import Options from "./components/Options/Options";
import SelectFields from "./components/SelectFields/SelectFields";

interface PaymentRequestExportProps {
    handleSnackbar: (message: string, severity: string) => void;
}

const PaymentRequestExport: FC<PaymentRequestExportProps> = ({ handleSnackbar }) => {
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isAppend, setIsAppend] = useState<boolean>(false);
    const [file, setFile] =  useState<File | null>(null);

    const product: FixMeLater = useAppSelector((state) => state?.Product.value);
    const company = useAppSelector(
        (state) => state?.[product?.productName]?.value?.company
    );
    const paymentRequestExport = useAppSelector(
        (state) => state?.[product?.productName]?.value?.paymentRequestExport
    );
    const municipalState: String = useAppSelector(
        (state) => state?.Municipal?.value.selectedState
    );
    const municipalQuarter: String = useAppSelector(
        (state) => state?.Municipal?.value.selectedQuarter,
    );
    const dispatch = useAppDispatch();
    const scheduleService = ScheduleService.getInstance();
    const subAccountOptionsService = SubAccountOptionsService.getInstance();
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        setIsFetching(true);

        const fetchData = async () => {
            try {
                const accessToken = await getAccessTokenSilently();

                const variables = {
                    scheduleInput: {
                        companyId: company?.id,
                        productId: ProductNameToIdMap.get(product?.productName),
                        taxYearId: product?.taxYear,
                        moduleId: getModuleId(product, company, municipalState),
                        folderId: product?.productName === "Premium" ? "104" : "102",
                        returnId: getScheduleId(product, municipalQuarter),
                        pageNumber: "0",
                    },
                };

                const { scheduleTemplateDTO }: FixMeLater =
                    await GQLService.fetchGraphQLData(
                        scheduleOnlyFieldsQuery,
                        variables,
                        accessToken
                    );

                const savedValues =
                    await subAccountOptionsService.getSubAccountOptions(
                        company?.id
                    );

                dispatch(
                    GlobalStateActions[
                        product?.productName
                    ]?.setPaymentRequestExport({
                        ...paymentRequestExport,
                        scheduleTemplateDTO,
                        payload: savedValues,
                    })
                );
            } catch (error: FixMeLater) {
                handleSnackbar(
                    `There has been an error fetching data. ${error?.message}`,
                    "error"
                );
            } finally {
                setIsFetching(false);
            }
        };

        if (paymentRequestExport?.open) fetchData();
    }, [paymentRequestExport?.open]);

    const handleClose = () => {
        setIsAppend(false);
        setFile(null);
        // reset redux state for payment request export
        dispatch(
            GlobalStateActions[product?.productName]?.setPaymentRequestExport(
                PaymentRequestExportInitialState
            )
        );
    };

    const exportPaymentRequest = async () => {
        setIsLoading(true);
        try {
            const formData = new FormData();
            if (file) {
                formData.append("file", file, file?.name);
            }
            const payload: ExportSchedulePayload = {
                exportOptions: paymentRequestExport?.payload,
                scheduleId: getScheduleId(product, municipalQuarter),
                scheduleKey: {
                    companyId: company?.id,
                    productId:
                        ProductNameToIdMap.get(product?.productName) || 0,
                    moduleId: Number(getModuleId(product, company, municipalState)) || 0,
                    taxYear: product?.taxYear,
                },
            };

            const attachmentMetadata = new Blob(
                [
                    JSON.stringify(
                        payload
                    ),
                ],
                { type: "application/json" },
            );

            formData.append("exportInput", attachmentMetadata);

            const { csvFile, contentDispositionHeader } =
                await scheduleService.getExportPaymentRequestCSV(formData);
            downloadFile(csvFile, contentDispositionHeader?.split("filename=")[1] || "exportedData.csv");

            await subAccountOptionsService.saveExportPaymentRequestOptions(
                company?.id,
                payload?.exportOptions
            );

            handleSnackbar("Exported successfully", "success");
            handleClose();
        } catch (error: FixMeLater) {
            handleSnackbar(
                `There has been an error exporting the payment request. ${error?.message}`,
                "error"
            );
        } finally {
            setIsLoading(false);
        }
    };

    const getContent = () => {
        const selectedTab = paymentRequestExport?.selectedTab;
        if (selectedTab === PAYMENT_REQUEST_EXPORT_TAB.GENERAL) {
            return (
                <General 
                    isAppend={isAppend} 
                    setIsAppend={setIsAppend} 
                    file={file} 
                    setFile={setFile} 
                    handleSnackbar={handleSnackbar}
                />
            );
        } else if (selectedTab === PAYMENT_REQUEST_EXPORT_TAB.OPTIONS) {
            return <Options />;
        } else if (selectedTab === PAYMENT_REQUEST_EXPORT_TAB.SELECT_FIELDS) {
            return <SelectFields />;
        } else if (selectedTab === PAYMENT_REQUEST_EXPORT_TAB.FIXED_WIDTH) {
            return <FixedWidth />;
        } else {
            return <div>Please select a tab.</div>;
        }
    };

    const content = (
        <>
            <div className="content-container">{getContent()}</div>
            <div className="action-buttons-container">
                <StyledFlexContainer justify={"center"}>
                    <Button
                        onClick={() => handleClose()}
                        color="primary"
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => exportPaymentRequest()}
                        color="primary"
                        variant="contained"
                    >
                        Export
                    </Button>
                </StyledFlexContainer>
            </div>
        </>
    );

    return (
        <Modal open={paymentRequestExport?.open} onClose={handleClose}>
            <div className="payment-request-export-container">
                <StyledModalContainer>
                    <div className="modal-header">Export Payment Request</div>
                    {isFetching || isLoading ? (
                        <Loader />
                    ) : (
                        <ContainerWithResizableSideBar
                            sideBarContent={<Menu />}
                            content={content}
                            initialWidth={160}
                            resizeEnabled={false}
                            toolbar={undefined}
                        />
                    )}
                </StyledModalContainer>
            </div>
        </Modal>
    );
};

export default PaymentRequestExport;
