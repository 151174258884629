import { useAuth0 } from "@auth0/auth0-react";
import { CircularProgress, ListItemIcon } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { MouseEvent, useEffect, useState } from "react";
import { ProductNameToIdMap } from "src/constants/ProductIds";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { ReturnDocumentService, ReturnPDFService } from "src/services";
import { returnTreeQuery } from "src/services/GQLQueries";
import GQLService from "src/services/GQLService";
import { getModuleId } from "src/services/Utility";
import {
    CreateReturnPDFPayload,
    FixMeLater,
    FolderNode as FolderNodeType,
    QueryParams,
    ReturnNode,
    TabProperties,
} from "src/types";
import CustomSnackbar from "../CustomSnackbar/CustomSnackbar";
import "./FolderNodeMenu.scss";
import { downloadFile, generateUniqueKey, getQueryParams } from "src/utils";
import {
    ChevronRightOutlined,
    RadioButtonUncheckedOutlined,
} from "@mui/icons-material";
import { ReturnStatusService } from "src/services/ReturnStatusService";
import { PRODUCT_NAME } from "src/constants";

type FolderNodeMenuProps = {
    anchorEl: null | HTMLElement;
    onCloseFolderNodeMenu: () => void;
    folderNode: FolderNodeType;
    onFolderNodeDialogOpen: (folderNode: FixMeLater) => void;
};

const FolderNodeMenu: React.FC<FolderNodeMenuProps> = ({
    anchorEl,
    onCloseFolderNodeMenu,
    folderNode,
    onFolderNodeDialogOpen,
}) => {
    const [lockAllLoading, setLockAllLoading] = useState<boolean>(false);
    const [unlockAllLoading, setUnlockAllLoading] = useState<boolean>(false);
    const [downloadAllReturnsLoading, setDownloadAllReturnsLoading] = useState<boolean>(false);
    const [downloadAllPaymentRequestsLoading, setDownloadAllPaymentRequestsLoading] = useState<boolean>(false);

    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>(
        "Returns updated successfully!"
    );
    const [snackbarSeverity, setSnackbarSeverity] = useState<string>("success");

    const [statusMenuAnchorEl, setStatusMenuAnchorEl] = useState(null);
    const [returnNodeStatus, setReturnNodeStatus] = useState<{
        [key: number]: string;
    }>({});
    const returnStatusService = ReturnStatusService.getInstance();
    const returnPDFService = ReturnPDFService.getInstance();

    const product: FixMeLater = useAppSelector((state) => state?.Product.value);
    const company = useAppSelector(
        (state) => state?.[product?.productName]?.value?.company
    );
    const municipalState: string = useAppSelector(
        (state) => state?.Municipal?.value.selectedState
    );
    const selectedQuarter = useAppSelector(
        (state: FixMeLater) =>
            state?.[product?.productName]?.value?.selectedQuarter
    );

    const tabsProperties = useAppSelector(
        (state) => state?.Tabs,
    )?.tabsProperties;

    const returnDocumentService = ReturnDocumentService.getInstance();

    const dispatch = useAppDispatch();

    const { getAccessTokenSilently } = useAuth0();

    const handleFolderNodeDialogOpen = (e: MouseEvent) => {
        e.stopPropagation();
        onCloseFolderNodeMenu();
        onFolderNodeDialogOpen(folderNode);
    };

    const updateLockedsInTabState = (
        tabsKeysArray: string[],
        locked: boolean
    ) => {
        tabsKeysArray.forEach((tabKey) => {
            if (tabsProperties[tabKey]) {
                const updatedTabProperties: TabProperties = {
                    ...tabsProperties[tabKey],
                    isLocked: locked,
                };
                dispatch(GlobalStateActions.setTab(updatedTabProperties));
            }
        });
    };

    const updateReturnsStatusInTabState = (returnStatus: number) => {
        const tabsKeysArray: string[] = [];
        const filteredReturnNodes = folderNode?.returnNodes?.filter(
            (returnNode) => returnNode.isActive
        );
        filteredReturnNodes?.forEach((returnNode) => {
            const uniqueKey = generateUniqueKey(folderNode,returnNode, product, company?.name);
            tabsKeysArray.push(uniqueKey);
        });
        tabsKeysArray.forEach((tabKey) => {
            if (tabsProperties[tabKey]) {
                const updatedTabProperties: TabProperties = {
                    ...tabsProperties[tabKey],
                    returnStatus: Number(returnStatus),
                };
                dispatch(GlobalStateActions.setTab(updatedTabProperties));
            }
        });
    };

    const updateAllReturnNodes = async (
        e: MouseEvent,
        actionType: "lock" | "unlock"
    ) => {
        e.stopPropagation();

        const loadingState =
            actionType === "lock" ? setLockAllLoading : setUnlockAllLoading;

        loadingState(true);

        try {
            const accessToken = await getAccessTokenSilently();
            const tabsKeysArray: string[] = [];

            const commonParams: QueryParams = getQueryParams({
                companyId: company?.id,
                productId: ProductNameToIdMap.get(product?.productName),
                taxYearId: product?.taxYear,
                folderId: folderNode?.id,
                moduleId: getModuleId(product, company, municipalState),
            });

            const createPayload = (returnNodes: ReturnNode[]) =>
                returnNodes?.map((returnNode) => {
                    const params: QueryParams = getQueryParams({
                        ...commonParams,
                        returnId: returnNode?.id,
                        retalFolderId: returnNode?.retalFolderId,
                    });
                    return params;
                });

            const filteredReturnNodes =
                actionType === "lock"
                    ? folderNode?.returnNodes?.filter(
                          (returnNode) =>
                              returnNode.isActive && !returnNode?.isLocked
                      )
                    : folderNode?.returnNodes?.filter(
                          (returnNode) =>
                              returnNode.isActive && returnNode?.isLocked
                      );

            const actionPayloads = createPayload(filteredReturnNodes);
            filteredReturnNodes?.forEach((returnNode) => {
                const uniqueKey = generateUniqueKey(
                    folderNode,
                    returnNode,
                    product,
                    company?.name,
                    municipalState,
                    selectedQuarter
                );
                tabsKeysArray.push(uniqueKey);
            });

            if (!actionPayloads?.length) {
                setSnackbarMessage("There are no returns to update.");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
                return;
            }

            let success = true;

            if (actionPayloads.length !== 0) {
                try {
                    if (actionType === "lock") {
                        try {
                            await returnDocumentService.lockReturnNodes(
                                actionPayloads
                            );

                            // update global state to reflect change in tabs
                            updateLockedsInTabState(tabsKeysArray, true);
                        } catch (error) {
                            success = false;
                        }
                    } else {
                        try {
                            await returnDocumentService.unlockReturnNodes(
                                actionPayloads,
                            );
                            // update global state to reflect change in tabs
                            updateLockedsInTabState(tabsKeysArray, false);
                        } catch (error) {
                            success = false;
                        }
                    }
                } catch (error) {
                    success = false;
                }
            }

            const treeInput: FixMeLater = {
                companyId: company?.id,
                productId: product?.productId,
                taxYearId: product?.taxYear,
                moduleId: getModuleId(product, company, municipalState),
            };

            const data: FixMeLater = await GQLService.fetchGraphQLData(
                returnTreeQuery,
                { treeInput },
                accessToken,
            );

            dispatch(
                GlobalStateActions[product?.productName].setTree(data.tree),
            );

            if (success) {
                handleSnackbar(
                    `Returns ${actionType}ed successfully!`,
                    "success",
                );
            } else {
                handleSnackbar(
                    "There has been an error updating the return statuses for some returns.",
                    "error",
                );
            }
        } catch (error) {
            handleSnackbar(
                "There has been an error updating the return statuses for some returns.",
                "error",
            );
        } finally {
            loadingState(false);
            onCloseFolderNodeMenu();
        }
    };

    const downloadAllMunicipalReturns = async (isPaymentRequest: boolean) => {
        try {
            isPaymentRequest
                ? setDownloadAllPaymentRequestsLoading(true)
                : setDownloadAllReturnsLoading(true);

            const returnDocId: CreateReturnPDFPayload = {
                returnDocId: {
                    companyId: company.id,
                    productId: product.productId,
                    taxYearId: product.taxYear,
                    folderId: Number(folderNode?.id),
                    moduleId: Number(
                        getModuleId(product, company, municipalState),
                    ),
                    returnId: 0,
                    retalFolderId: Number(folderNode?.id),
                    paymentRequestFormId: isPaymentRequest ? 1171 : 0,
                },
            };

            const returnsDownloadable =
                await returnPDFService.generateDownloadableReturnsBulk(
                    returnDocId,
                );
            const { blob, contentDispositionHeader } = returnsDownloadable;
            downloadFile(blob, contentDispositionHeader?.split("filename=")[1]);
            handleSnackbar("Downloaded successfully", "success");
        } catch (error) {
            handleSnackbar("Error downloading files", "error");
            console.error("Error downloading files:", error);
        } finally {
            isPaymentRequest
                ? setDownloadAllPaymentRequestsLoading(false)
                : setDownloadAllReturnsLoading(false);
            onCloseFolderNodeMenu();
        }
    };


    const downloadAllFolderReturns = async () => {
        try {
           
            setDownloadAllReturnsLoading(true);
            const returnDocId: CreateReturnPDFPayload = {
                returnDocId: {
                    companyId: company.id,
                    productId: product.productId,
                    taxYearId: product.taxYear,
                    folderId: Number(folderNode?.id),
                    moduleId: Number(
                        getModuleId(product, company, municipalState),
                    ),
                    returnId: 0,
                    retalFolderId: Number(folderNode?.id),
                },
            };

            const returnsDownloadable =
                await returnPDFService.generateDownloadableAllPdf(
                    returnDocId,
                );
            const { blob, contentDispositionHeader } = returnsDownloadable;
            downloadFile(blob, contentDispositionHeader?.split("filename=")[1]);
            handleSnackbar("Downloaded successfully", "success");
        } catch (error) {
            handleSnackbar("Error downloading files", "error");
            console.error("Error downloading files:", error);
        } finally {
            setDownloadAllReturnsLoading(false);
            onCloseFolderNodeMenu();
        }
    };

    const handleSnackbar = (message: string, severity: string) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleStatusMenuClick = async (event) => {
        setStatusMenuAnchorEl(event.currentTarget);
        try {
            const payload: QueryParams = {
                companyId: company?.id,
                productId: `${ProductNameToIdMap.get(product?.productName)}`,
                taxYearId: product?.taxYear,
                folderId: folderNode?.id.toString(),
                moduleId: getModuleId(product, company, municipalState),
                returnId: "0",
                retalFolderId:
                    folderNode.returnNodes[0]?.retalFolderId?.toString() ?? "",
            };
            const result = await returnStatusService.getReturnStatuses(payload);
            setReturnNodeStatus(result);
        } catch (error) {
            console.error("Error fetching statuses:", error);
        }
    };

    const handleStatusMenuClose = () => {
        setStatusMenuAnchorEl(null);
    };

    const handleSetStatus = async (statusKey) => {
        try {
            const commonParams: QueryParams = {
                companyId: company?.id,
                productId: `${ProductNameToIdMap.get(product?.productName)}`,
                taxYearId: product?.taxYear,
                folderId: folderNode?.id.toString(),
                moduleId: getModuleId(product, company, municipalState),
                returnId: "0",
                retalFolderId:
                    folderNode.returnNodes[0]?.retalFolderId?.toString() ?? "",
                returnStatus: statusKey,
            };
            const result =
                await returnStatusService.setReturnStatuses(commonParams);
            const accessToken = await getAccessTokenSilently();
            const treeInput: FixMeLater = {
                companyId: company?.id,
                productId: product?.productId,
                taxYearId: product?.taxYear,
                moduleId: getModuleId(product, company, municipalState),
            };

            const data: FixMeLater = await GQLService.fetchGraphQLData(
                returnTreeQuery,
                { treeInput },
                accessToken,
            );

            dispatch(
                GlobalStateActions[product?.productName].setTree(data.tree),
            );
            handleSnackbar("Returns status have been updated", "success");
            updateReturnsStatusInTabState(statusKey);
        } catch (error) {
            console.error("Error updating statuses:", error);
            handleSnackbar("Unable to save returns status", "error");
        }
        handleStatusMenuClose();
        onCloseFolderNodeMenu();
    };

    return (
        <div>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={onCloseFolderNodeMenu}
                TransitionProps={{
                    timeout: 0,
                }}
            >
                {folderNode?.attributes?.displayName !== "Municipal Returns Reconciliation" && // Municipal Returns Reconciliation cannot be activated
                folderNode.returnNodes.some(
                    (returnNode) => !returnNode.isActive,
                ) && (
                    <MenuItem onClick={handleFolderNodeDialogOpen}>
                        Activate
                    </MenuItem>
                )}

                <MenuItem onClick={(e) => updateAllReturnNodes(e, "lock")}>
                    Lock All
                    {lockAllLoading && (
                        <div className="folder-node-menu-loading-icon-container">
                            <CircularProgress size={20} />
                        </div>
                    )}
                </MenuItem>
                <MenuItem onClick={(e) => updateAllReturnNodes(e, "unlock")}>
                    Unlock All
                    {unlockAllLoading && (
                        <div className="folder-icon-container">
                            <CircularProgress size={20} />
                        </div>
                    )}
                </MenuItem>
                {product?.productName === PRODUCT_NAME.MUNICIPAL &&
                folderNode?.returnNodes?.some(
                    (returnNode) => returnNode?.isActive,
                ) ? (
                    <>
                        <MenuItem
                            onClick={() => downloadAllMunicipalReturns(false)}
                        >
                            Download all Municipal Returns
                            {downloadAllReturnsLoading && (
                                <div className="folder-node-menu-loading-icon-container">
                                    <CircularProgress size={20} />
                                </div>
                            )}
                        </MenuItem>
                        <MenuItem
                            onClick={() => downloadAllMunicipalReturns(true)}
                        >
                            Download all Payment Requests
                            {downloadAllPaymentRequestsLoading && (
                                <div className="folder-node-menu-loading-icon-container">
                                    <CircularProgress size={20} />
                                </div>
                            )}
                        </MenuItem>
                    </>
                ) : (
                    // Add your else logic here
                    <MenuItem
                            onClick={() => downloadAllFolderReturns()}
                        >
                            Download All to Pdf
                            {downloadAllReturnsLoading && (
                                <div className="folder-node-menu-loading-icon-container">
                                    <CircularProgress size={20} />
                                </div>
                            )}
                        </MenuItem>
                )}
                <MenuItem
                    onClick={handleStatusMenuClick}
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                >
                    Set Status
                    <ChevronRightOutlined />
                </MenuItem>
            </Menu>
            <Menu
                anchorEl={statusMenuAnchorEl}
                open={Boolean(statusMenuAnchorEl)}
                onClose={handleStatusMenuClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                {Object.entries(returnNodeStatus).map(([statusKey, status]) => (
                    <MenuItem
                        onClick={() => handleSetStatus(statusKey)}
                        key={statusKey}
                    >
                        <ListItemIcon>
                            <RadioButtonUncheckedOutlined />
                            {/* Replace this with logic to choose the icon */}
                        </ListItemIcon>
                        {status}
                    </MenuItem>
                ))}
            </Menu>
            <CustomSnackbar
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />
        </div>
    );
};

export default FolderNodeMenu;
