import styled from "styled-components";
import { Card, CardContent, Typography } from "@mui/material";
import colors from "src/styles/colors.scss";

interface StyledJobCardProps {
    $isSelected: boolean;
}

export const StyledJobCard = styled(Card)<StyledJobCardProps>`
    box-sizing: border-box;
    width: 220px;
    height: 116px;
    margin-bottom: 12px;
    cursor: pointer;
    background-color: ${(props) =>
        props.$isSelected
            ? colors.allocatorContainerBackgroundColor
            : colors.white};
    border-color: ${(props) =>
        props.$isSelected
            ? colors.allocatorSelectedContainerBorderColor
            : colors.grey};
`;

export const StyledJobCardContent = styled(CardContent)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px !important;

    .MuiBox-root {
        overflow: hidden;
    }

    .job-card-actions {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-right: -4px;

        .MuiIconButton-root {
            padding: 2px;
        }
    }
`;

export const StyledTypography = styled(Typography)`
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;
