import { Menu, MenuItem } from "@mui/material";
import React, { FC, MouseEvent, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { AllocatorService } from "src/services";
import {
    AllocatorJob,
    AllocatorJobStatus,
    FixMeLater,
    JOB_STATUS_TYPES,
} from "src/types";
import {
    StyledCircularProgress,
    StyledExpandIcon,
    StyledInfoOutlined,
    StyledJobStatus,
} from "./JobStatus.styled";
import { useSnackbar } from "notistack";
import { HttpError } from "src/utils/HttpError";

interface JobStatusProps {
    jobStatus: AllocatorJobStatus;
    allowedStatuses: AllocatorJobStatus[];
    jobId: number;
    progress: number | undefined;
}

const JobStatus: FC<JobStatusProps> = ({
    jobStatus,
    allowedStatuses,
    jobId,
    progress = 0,
}) => {
    const allocatorService = AllocatorService.getInstance();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );
    const selectedJob: AllocatorJob = useAppSelector(
        (state) => state?.[product?.productName]?.value?.jobs?.selectedJob
    );

    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useAppDispatch();

    const handleClickStatus = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation();

        if (allowedStatuses?.length || jobStatus?.name === JOB_STATUS_TYPES.FAILED) {
            setAnchorEl(event?.currentTarget);
        }
    };

    const handleCloseMenu = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(null);
    };

    const handleUpdateStatus = (event: MouseEvent<HTMLElement>) => {
        handleCloseMenu(event);
        updateStatus(event?.currentTarget?.dataset?.name as JOB_STATUS_TYPES);
    };

    const updateStatus = async (jobStatus: JOB_STATUS_TYPES) => {
        setIsLoading(true);

        try {
            const updatedJob = await allocatorService.updateJobStatus(jobId, {
                jobStatus,
            });
            if (jobId === selectedJob.id) {
                dispatch(
                    GlobalStateActions[product?.productName].setSelectedJob(
                        updatedJob
                    )
                );
                dispatch(
                    GlobalStateActions[
                        product?.productName
                    ].setToogleFetchReport()
                );
            }
        } catch (error) {
            if(error instanceof HttpError) {
                enqueueSnackbar(JSON.parse(error?.body)?.errorDetails, { variant: "error" });
            } else {
                enqueueSnackbar("Error updating job status", { variant: "error" });
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <StyledJobStatus
                variant="contained"
                $status={jobStatus?.name}
                loading={isLoading}
                onClick={handleClickStatus}
                endIcon={
                    <>
                        {jobStatus?.name === JOB_STATUS_TYPES.PROCESSING && (
                            <StyledCircularProgress
                                size={16}
                                thickness={5}
                                variant="determinate"
                                value={progress}
                            />
                        )}
                        {jobStatus?.name === JOB_STATUS_TYPES.FAILED && (
                            <StyledInfoOutlined />
                        )}
                        {(jobStatus?.name === JOB_STATUS_TYPES.PROCESSED ||
                            jobStatus?.name === JOB_STATUS_TYPES.ON_REVIEW ||
                            jobStatus?.name === JOB_STATUS_TYPES.APPROVED) && (
                            <StyledExpandIcon />
                        )}
                    </>
                }
            >
                {jobStatus?.name === JOB_STATUS_TYPES.PROCESSING? `${jobStatus?.value} (${progress?.toFixed(1)}%)` : jobStatus?.value}
            </StyledJobStatus>
            {!!allowedStatuses?.length && (
                <Menu
                    anchorEl={anchorEl}
                    open={!!anchorEl}
                    onClose={handleCloseMenu}
                    onContextMenu={handleCloseMenu}
                >
                    {allowedStatuses?.map(
                        (status: AllocatorJobStatus, index: number) => {
                            return (
                                <MenuItem
                                    key={index}
                                    onClick={handleUpdateStatus}
                                    data-name={status?.name}
                                >
                                    {status?.name === JOB_STATUS_TYPES.CANCELED
                                        ? "Cancel processing"
                                        : status?.value}
                                </MenuItem>
                            );
                        }
                    )}
                </Menu>
            )}
        </>
    );
};

export default JobStatus;
