import { BaseAPIService } from "src/services/BaseApiService";
import { AllocatorCorrectionsPayload, AllocatorErrorLog, AllocatorJob, AllocatorJobsPayload, AllocatorJobStatistics, AllocatorJobStatusPayload, AllocatorJobsPage, AllocatorReport, AllocatorReportPayload, AllocatorReportTab, AllocatorUploadResult, FILE_FORMAT_TYPES, MimeType, AllocatorResponseDownloadable, AllocatorFieldMapping } from "src/types";

export class AllocatorService {

    private static _instance: AllocatorService;
    private readonly BASE_PATH: string = "/api/v1";
    private readonly WS_PATH: string = `${process.env.REACT_APP_STRATUS_API_BASE_WS_URL}`;
    private readonly TIME_ZONE: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
    private api: BaseAPIService = BaseAPIService.getInstance();

    public static getInstance(): AllocatorService {
        if (!AllocatorService._instance) {
            AllocatorService._instance = new AllocatorService();
        }
        return AllocatorService._instance;
    }

    private getPath(path: string): string {
        return `${this.BASE_PATH + path}`;
    }

    private getWebSocketPath(path: string): string {
        return `${this.WS_PATH + this.BASE_PATH + path}`;
    }

    // GET

    getJobErrorLog(jobId: number): Promise<AllocatorErrorLog> {
        return this.api.get<AllocatorErrorLog>(this.getPath(`/jobs/${jobId}/errorlog`));
    }

    getJobStatistics(jobId: number): Promise<AllocatorJobStatistics> {
        return this.api.get<AllocatorJobStatistics>(this.getPath(`/jobs/${jobId}/statistics`));
    }

    getReportsList(jobId: string): Promise<AllocatorReportTab[]> {
        return this.api.get<AllocatorReportTab[]>(this.getPath(`/reports?jobId=${jobId}`));
    }

    getNotifications(): Promise<Response> {
        return this.api.get<Response>(this.getPath("/notifications/sse/customer"), {}, true);
    }

    getAccountSettings(): Promise<AllocatorFieldMapping> {
        return this.api.get<AllocatorFieldMapping>(this.getPath("/account/settings"));
    }

    getErrorLog(id: number): Promise<AllocatorErrorLog> {
        return this.api.get<AllocatorErrorLog>(this.getPath(`/errors/${id}/logs`));
    }

    async downloadLogs(id: number): Promise<AllocatorResponseDownloadable> {
        const response = await this.api.get<Response>(
            this.getPath(`/errors/${id}/logs/download`),
            undefined,
            true,
        );

        const contentDispositionHeader = response.headers.get(
            "Content-Disposition"
        ) ?? "";

        return response.blob().then((blob) => ({ blob, contentDispositionHeader }));
    }

    async downloadAccountSettings(): Promise<AllocatorResponseDownloadable> {
        const response = await this.api.get<Response>(
            this.getPath("/account/settings/download"),
            undefined,
            true,
        );

        const contentDispositionHeader = response.headers.get(
            "Content-Disposition"
        ) ?? "";

        return response.blob().then((blob) => ({ blob, contentDispositionHeader }));
    }

    // POST

    generateJobsList(page: number = 0, size: number = 10, data: AllocatorJobsPayload): Promise<AllocatorJobsPage> {
        return this.api.post<AllocatorJobsPage>(this.getPath(`/jobs?page=${page}&size=${size}`), data);
    }

    generateReportData(reportId: number, data: AllocatorReportPayload, page: number, size: number): Promise<AllocatorReport> {
        return this.api.post<AllocatorReport>(this.getPath(`/reports/${reportId}/preview?page=${page}&size=${size}`), data);
    }

    applyAddressCorrections(jobId: number, corrections: AllocatorCorrectionsPayload): Promise<void> {
        return this.api.post<void>(this.getPath(`/jobs/${jobId}/correction`), corrections);
    }

    addressForceAllocate(jobId: number, sourceRowId: number): Promise<void> {
        return this.api.post<void>(this.getPath(`/jobs/${jobId}/address/${sourceRowId}/force-allocate`));
    }

    uploadJob(files: FormData): Promise<AllocatorUploadResult> {
        return this.api.post<AllocatorUploadResult>(this.getPath("/addresses/upload"), files, {
            headers: {
                "Content-Type": MimeType.MULTIPART_FORM_DATA
            }
        });
    }

    uploadSettings(file: FormData): Promise<AllocatorFieldMapping> {
        return this.api.post<AllocatorFieldMapping>(this.getPath("/account/settings"), file, {
            headers: {
                "Content-Type": MimeType.MULTIPART_FORM_DATA
            }
        });
    }

    async generateCsvReport(reportId: number, data: AllocatorReportPayload): Promise<AllocatorResponseDownloadable> {
        const response = await this.api.post<Response>(
            this.getPath(`/reports/${reportId}/download/csv?tz=${this.TIME_ZONE}`),
            data,
            undefined,
            true,
        );

        const contentDispositionHeader = response.headers.get(
            "Content-Disposition"
        ) ?? "";

        return response.blob().then((blob) => ({ blob, contentDispositionHeader }));
    }

    async generateImpReport(exportParam: string, data: AllocatorReportPayload): Promise<AllocatorResponseDownloadable> {
        const response = await this.api.post<Response>(
            this.getPath(`/reports/download/imp?exportParam=${exportParam}`),
            data,
            undefined,
            true,
        );

        const contentDispositionHeader = response.headers.get(
            "Content-Disposition"
        ) ?? "";

        return response.blob().then((blob) => ({ blob, contentDispositionHeader }));
    }

    async generateAllReports(data: AllocatorReportPayload): Promise<AllocatorResponseDownloadable> {
        const response = await this.api.post<Response>(
            this.getPath(`/reports/download/csv?tz=${this.TIME_ZONE}`),
            data,
            undefined,
            true,
        );

        const contentDispositionHeader = response.headers.get(
            "Content-Disposition"
        ) ?? "";

        return response.blob().then((blob) => ({ blob, contentDispositionHeader }));
    }

    // PUT

    updateJobStatus(jobId: number, jobStatus: AllocatorJobStatusPayload): Promise<AllocatorJob> {
        return this.api.put<AllocatorJob>(this.getPath(`/jobs/${jobId}/status`), jobStatus);
    }

    // DELETE

    deleteJob(jobId: number): Promise<void> {
        return this.api.delete<void>(this.getPath(`/jobs/${jobId}`));
    }

    deleteSingleAddress(jobId: number, sourceRowId: number): Promise<void> {
        return this.api.delete<void>(this.getPath(`/jobs/${jobId}/record/${sourceRowId}`));
    }

    deleteAccountSettings(): Promise<AllocatorFieldMapping> {
        return this.api.delete<AllocatorFieldMapping>(this.getPath("/account/settings"));
    }

    // WEB SOCKET

    async openWebSocketConnection(): Promise<WebSocket> {
        const token = await this.api.getToken();
        return new WebSocket(this.getWebSocketPath(`/ws?authorization=${token}`));
    }

}
